import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewChips from '../common/NewChips';
import { FilterModal } from '../common/FilterModal';
import { BUILDING_TYPE_ID } from '../../../utils/helpers/constants';

const buildingTypeOptions = t => [
  { id: BUILDING_TYPE_ID.FLAT_NEW, name: t('new_search.new_building') },
  {
    id: BUILDING_TYPE_ID.FLAT_SECONDARY,
    name: t('new_search.secondary_building')
  }
];

export const ModalsWithFilters = forwardRef(
  ({ values, change, submit, hasCategory }, ref) => {
    const { t } = useTranslation();
    const [modalConfig, setModalConfig] = useState();
    const categoryFlat = [
      BUILDING_TYPE_ID.FLAT_ALL,
      BUILDING_TYPE_ID.FLAT_NEW,
      BUILDING_TYPE_ID.FLAT_SECONDARY
    ].includes(values.category_id);
    const categoryHouse = values.category_id === BUILDING_TYPE_ID.HOUSE;
    const categoryOffice = values.category_id === BUILDING_TYPE_ID.OFFICE;

    const hideModal = () => setModalConfig(null);

    useImperativeHandle(
      ref,
      () => {
        return { getModalConfig: () => modalConfig };
      },
      [modalConfig]
    );

    const onlyPriceTo = values.price_to
      ? t('search.search_title_service.to', {
          to: values.price_to,
          unit: 'AZN'
        })
      : t('new_search.price');
    const onlyPriceFrom = values.price_from
      ? t('search.search_title_service.from', {
          from: values.price_from,
          unit: 'AZN'
        })
      : onlyPriceTo;
    const price =
      values.price_from && values.price_to
        ? `${values.price_from} - ${values.price_to} AZN`
        : onlyPriceFrom;

    const roomsCountTitle = values.room_ids?.length
      ? t('new_search.roominess', { count: values.room_ids.join(', ') })
      : t('new_search.rooms');

    const metres = t('search.search_title_service.units.metres');
    const acres = t('search.search_title_service.units.acres');
    const isLand = values.category_id === '9';
    const unit = isLand ? acres : metres;
    const onlyAreaTo = values.area_to
      ? t('search.search_title_service.to', { to: values.area_to, unit })
      : t('new_search.area');
    const onlyAreaFrom = values.area_from
      ? t('search.search_title_service.from', { from: values.area_from, unit })
      : onlyAreaTo;
    const area =
      values.area_from && values.area_to
        ? `${values.area_from} - ${values.area_to} ${unit}`
        : onlyAreaFrom;

    const [categoryIds, setCategoryIds] = useState([]);

    function handleBuildingType(id) {
      setCategoryIds(prev => {
        let result = [];
        if (prev.includes(id)) {
          result = prev.filter(prevId => prevId !== id);
        } else {
          result = [...prev, id];
        }
        const isSelectedAll = !result.length || result.length > 1;
        change(
          'category_id',
          isSelectedAll ? BUILDING_TYPE_ID.FLAT_ALL : result[0]
        );
        submit();
        return result;
      });
    }

    return (
      <div
        className="new-search__chips-wrapper"
        style={hasCategory ? { marginTop: '10px' } : {}}
      >
        {categoryFlat &&
          buildingTypeOptions(t).map(buildingTypeOption => {
            return (
              <NewChips
                key={buildingTypeOption.id}
                cypress={`new-search-building-type-${buildingTypeOption.id}`}
                type="button"
                dirty={categoryIds.includes(buildingTypeOption.id)}
                useDirtyAsActive
                text={buildingTypeOption.name}
                onClick={() => handleBuildingType(buildingTypeOption.id)}
                resetHandler={() => handleBuildingType(buildingTypeOption.id)}
                isDark
              />
            );
          })}

        <NewChips
          type="button"
          text={price}
          dirty={values.price_from || values.price_to}
          useDirtyAsActive
          resetHandler={() => {
            change('price_from', '');
            change('price_to', '');
            submit();
          }}
          stat="new-search-price-modal"
          onClick={() => setModalConfig('price')}
          iconType="angle"
          isDark
        />

        <NewChips
          type="button"
          text={area}
          dirty={values.area_from || values.area_to}
          useDirtyAsActive
          stat="new-search-area-modal"
          resetHandler={() => {
            change('area_from', '');
            change('area_to', '');
            submit();
          }}
          onClick={() => setModalConfig('area')}
          iconType="angle"
          isDark
        />
        {(categoryFlat || categoryHouse || categoryOffice) && (
          <NewChips
            type="button"
            text={roomsCountTitle}
            dirty={!!values.room_ids}
            useDirtyAsActive
            stat="new-search-rooms-count-modal"
            resetHandler={() => {
              change('room_ids');
              submit();
            }}
            onClick={() => setModalConfig('roomsCount')}
            iconType="angle"
            isDark
          />
        )}

        <FilterModal
          hide={hideModal}
          isOpen={!!modalConfig}
          modalConfig={modalConfig}
        />
      </div>
    );
  }
);

ModalsWithFilters.displayName = 'ModalsWithFilters';

ModalsWithFilters.propTypes = {
  sort: PropTypes.string,
  change: PropTypes.func,
  submit: PropTypes.func,
  values: PropTypes.object,
  hasCategory: PropTypes.bool
};
